<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="javascript:void(0);"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <router-link
        :to="`/admin/edit/timeline/${timelineId}`"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Edit
      </router-link>
      <a
        href="javascript:void(0);"
        @click="removeTimeline"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        Delet
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { mapActions } from "vuex";

export default {
  props: {
    timelineId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },
  methods: {
    ...mapActions("apiData", ["deleteTimeline"]),

    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },

    async removeTimeline() {
      await this.deleteTimeline(this.timelineId);
    },
  },
};
</script>
