// store/modules/loader.js

export default {
    namespaced: true,
    state: {
      isLoading: false,
    },
    mutations: {
      SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
      },
    },
    actions: {
      startLoading({ commit }) {
        commit("SET_LOADING", true);
      },
      stopLoading({ commit }) {
        commit("SET_LOADING", false);
      },
    },
    getters: {
      isLoading: (state) => state.isLoading,
    },
  };  