<template>
  <div class="flex items-center mb-4">
    <label class="text-blueGray-600 text-lg font-bold mr-2" for="apiKey"
      >API Key:</label
    >
    <input
      id="apiKey"
      v-if="!isLoading && apiKey"
      v-model="apiKey"
      type="text"
      class="bg-gray-200 border-gray-200 text-gray-500 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
      disabled
      readonly
    />
    <loader v-else-if="isLoading" />
    <button
      v-if="!apiKey && !isLoading"
      @click="handleApiKey"
      class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    >
      Generate API Key
    </button>
    <button
      v-if="apiKey"
      @click="copyApiKey"
      class="text-blue-500 cursor-pointer mr-2"
    >
      <i class="fas fa-copy"></i>
    </button>
    <button
      v-if="apiKey"
      @click="handleApiKey"
      class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    >
      Regenerate API Key
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader/Loader.vue";

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapGetters("apiData", ["apiKey"]),
    ...mapGetters("loader", ["isLoading"]),
  },
  methods: {
    ...mapActions("apiData", ["fetchApiKey", "generateApiKey"]),
    ...mapActions("notificationsModule", ["showSuccess", "showError"]),
    async handleApiKey() {
      await this.generateApiKey();
      await this.fetchApiKey();
    },
    copyApiKey() {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(this.apiKey)
          .then(() => {
            this.showSuccess("Code copied to clipboard");
          })
          .catch((err) => {
            this.showError("Failed to copy: ", err);
          });
      } else {
        // Fallback pour les navigateurs ne supportant pas l'API Clipboard
        const el = document.createElement("textarea");
        el.value = this.apiKey;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        this.showSuccess("Code copied to clipboard");
      }
    },
  },
  mounted() {
    this.fetchApiKey();
  },
};
</script>
