// store/modules/authModule.js
import Cookies from "js-cookie";
import WpApiService from "@/services/WpApiService";

export default {
  namespaced: true,

  state: {
    token: null,
    username: null,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USERNAME(state, username) {
      state.username = username;
    },
  },

  actions: {
    async signIn({ commit, dispatch }, { email, password }) {
      try {
        const data = await WpApiService.signIn(email, password);
        Cookies.set("jwt_token", data.token, { expires: 7 });
        Cookies.set("wp_username", data.user_nicename, { expires: 7 });
        commit("SET_TOKEN", data.token);
        commit("SET_USERNAME", data.user_nicename);
        return true;
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          "Error on login, check your username or password.",
          {
            root: true,
          }
        );
      }
    },
    loadToken({ commit }) {
      const token = Cookies.get("jwt_token");
      const username = Cookies.get("wp_username");
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (username) {
        commit("SET_USERNAME", username);
      }
    },
    loadUsername({ commit }) {
      const username = Cookies.get("wp_username");
      if (username) {
        commit("SET_USERNAME", username);
      }
    },
    signOut({ commit }, router) {
      Cookies.remove("jwt_token");
      commit("SET_TOKEN", null);
      router.push({ path: "/auth/login", query: { loggedOut: "true" } });
    },
  },

  getters: {
    token: (state) => state.token,
    username: (state) => state.username,
  },
};
