import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist'
import localforage from 'localforage';

import authModule from '@/store/modules/authModule';
import rememberMeModule from '@/store/modules/rememberMeModule';
import notificationsModule from '@/store/modules/notificationsModule';
import apiData from '@/store/modules/apiData';
import timelineDataModule from '@/store/modules/timelineDataModule';
import loader from '@/store/modules/loader';
import filterModule from '@/store/modules/filterModule';

const vuexLocalStorage = new VuexPersist({
  key: 'nowmuseum-app', 
  storage: localforage,
  reducer: (state) => ({
    rememberMeModule: state.rememberMeModule,
  }),
});

const store = createStore({
  modules: {
    authModule,
    rememberMeModule,
    notificationsModule,
    apiData,
    timelineDataModule,
    loader,
    filterModule,
  },
  plugins: [vuexLocalStorage.plugin],
});

export default store;