<template>
  <div>
    <!-- Notifications -->
    <inline-notification />

    <div
      v-if="!isLoading"
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-2xl rounded-lg bg-blueGray-200 border-0"
    >
      <div class="flex-auto px-4 lg:px-10 py-10 pt-5">
        <form @submit.prevent="edTimeline">
          <!-- Timeline -->
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Configure your Timeline
          </h6>

          <timeline-filter-components :timeline="timeline" />

          <hr class="my-4 md:min-w-full" />

          <div class="flex flex-wrap">
            <!-- Name -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="statut"
                >
                  Name timeline
                </label>
                <input
                  v-if="!isLoading"
                  id="name"
                  name="name"
                  v-model="name"
                  class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  required
                />
                <loader v-else />
              </div>
            </div>
            <!-- Embed Code -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="code"
                >
                  EMBED CODE
                </label>
                <textarea
                  v-if="!isLoading"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-blueGray-100 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="embedCode"
                  readonly
                ></textarea>
                <loader v-else />
                <button
                  @click="copyEmbedCode"
                  :disabled="
                    embedCode === 'Please select one filter' || embedCode === ''
                  "
                  class="mt-2 theme-secondary copy-btn text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                >
                  Copy Code
                </button>
              </div>
            </div>
          </div>
          <!-- Bouton de création -->
          <div class="text-center mt-6">
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Edit Timeline
            </button>
            <button
              @click="previewTimeline"
              :disabled="isSearchQueryValid"
              class="theme-primary-bk copy-btn text-white active:theme-secondary-bk text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              :title="
                filters.searchQuery.length < max_query
                  ? `Search term must be at least ${max_query} characters long`
                  : ''
              "
            >
              Preview
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InlineNotification from "@/components/Notifications/InlineNotification.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import TimelineFilterComponents from "@/components/Filters/TimelineFilterComponents.vue";
import Loader from "@/components/Loader/Loader.vue";

export default {
  components: {
    InlineNotification,
    TimelineFilterComponents,
    Loader,
  },
  data() {
    return {
      isLoading: false,
      timelineEdit: [],
      name: "",
      isSearchQueryValid: false,
      max_query : process.env.VUE_APP_MAX_SEARCH_QUERY || 5,
    };
  },
  computed: {
    ...mapState("filterModule", ["filters", "embedCode", "previewRequested"]),
    ...mapState("apiData", ["timeline"]),
  },
  watch: {
    "filters.searchQuery"(newQuery) {
      this.isSearchQueryValid = newQuery.length <= this.max_query;
    },
  },
  methods: {
    ...mapActions("apiData", ["fetchTimelineById", "editTimeline"]),
    ...mapActions("filterModule", ["updateFilters", "previewTimeline"]),
    ...mapActions("notificationsModule", ["showSuccess", "showError"]),
    ...mapMutations("filterModule", ["SET_PREVIEW_REQUESTED"]),

    async edTimeline() {
      this.timelineEdit = {
        name: this.name,
        searchQuery: this.filters.searchQuery,
        country: this.filters.country,
        language: this.filters.language,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };
      await this.editTimeline({
        timelineId: this.$route.params.id,
        timeline: this.timelineEdit,
      });
    },

    async previewTimeline() {
      try {
        this.SET_PREVIEW_REQUESTED(true);
      } catch (error) {
        this.showError("Error generating preview");
      }
    },

    async simulatePreview() {
      if (!this.isSearchQueryValid) {
        await this.previewTimeline();
      }
    },

    copyEmbedCode() {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(this.embedCode)
          .then(() => {
            this.showSuccess("Code copied to clipboard");
          })
          .catch((err) => {
            this.showError("Failed to copy: ", err);
          });
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = this.embedCode;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          this.showSuccess("Code copied to clipboard");
        } catch (err) {
          this.showError("Failed to copy: ", err);
        }
        document.body.removeChild(textArea);
      }
    },
  },
  async mounted() {
    this.isSearchQueryValid = this.filters.searchQuery.length <= this.max_query;
    await this.fetchTimelineById(this.$route.params.id);
    if (this.timeline) {
      this.name = this.timeline.name;
      this.$nextTick(this.simulatePreview);
    }
  },
};
</script>

<style>
.timeline-admin {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.copy-btn {
  cursor: pointer;
}

.bg-emerald-500:disabled {
  background-color: #a5d6a7; /* Lighter shade for disabled state */
  cursor: not-allowed !important;
  opacity: 0.5;
}
.timeline-admin .tl-timeline h1,
.timeline-admin .tl-timeline h2,
.timeline-admin .tl-timeline h3,
.timeline-admin .tl-timeline h4,
.timeline-admin .tl-timeline h5,
.timeline-admin .tl-timeline h6 {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
}

.timeline-admin .tl-timeline .tl-slide-content-container .tl-headline {
  font-size: 21px;
  line-height: 26px;
}

.timeline-admin .tl-timeline a {
  color: #72b3d0 !important;
}

.timeline-admin .tl-timeline .tl-text-content p {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
  color: #000 !important;
}

.timeline-admin .tl-timeline .tl-text-content p a {
  color: #72b3d0 !important;
}

.timeline-admin .tl-timeline .tl-headline-date,
.timeline-admin .tl-timeline .tl-headline-date small {
  color: #000 !important;
}
</style>
