<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <!-- Notifications -->
        <inline-notification />

        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-8">
            <h1 class="font-semibold text-4xl theme-secondary pb-6"> Login </h1>
            <form @submit.prevent="login">
              <div class="relative w-full mb-3">
                <!-- Email Input -->
                <label
                  class="block uppercase theme-secondary text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email / Username
                </label>
                <input
                  id="email"
                  v-model="email"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 theme-secondary bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <!-- Password Input -->
                <label
                  class="block uppercase theme-secondary text-xs font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 theme-secondary bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="rememberMeForm"
                    v-model="rememberMeForm"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold theme-secondary">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="theme-primary-bk text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <a :href="resetPasswordLink" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div>
          <div class="w-1/2 text-right">
            <a :href="registerPasswordLink" class="text-blueGray-200">
              <small>Create new account</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import InlineNotification from "@/components/Notifications/InlineNotification.vue";
import github from "@/assets/img/github.svg";
import google from "@/assets/img/google.svg";

export default {
  name: "login-view",
  components: {
    InlineNotification,
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMeForm: false,
      github,
      google,
      registerPasswordLink: process.env.VUE_APP_RESET_REGISTER_LINK,
      resetPasswordLink: process.env.VUE_APP_RESET_PASSWORD_LINK,
    };
  },
  computed: {
    ...mapGetters("authModule", ["token"]),
  },
  watch: {
    async rememberMeForm(newValue) {
      await this.saveRememberMe(newValue);
    },
  },
  methods: {
    ...mapActions("authModule", ["signIn", "loadToken"]),
    ...mapActions("rememberMeModule", ["saveRememberMe"]),
    async login() {
      const success = await this.signIn({
        email: this.email,
        password: this.password,
      });
      if (success) {
        this.$router.push("/admin/dashboard");
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.rememberMeForm = this.$store.getters["rememberMeModule/rememberMe"];
    });
  },
  mounted() {
    this.loadToken();
    if (this.token) {
      this.$router.push("/admin/dashboard");
    }
  },
};
</script>
