window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import "./utils/localForageConfig";
import store from "./store/store";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "vue-search-select/dist/VueSearchSelect.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/AdminLayout.vue";
import Auth from "@/layouts/AuthLayout.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/ProfileSettings.vue";
import Timelines from "@/views/admin/AllTimelines.vue";
import AddTimeline from "@/views/admin/AddTimeline.vue";
import EditTimeline from "@/views/admin/EditTimeline.vue";

// views for Auth layout

import Login from "@/views/auth/LoginPage.vue";
import Register from "@/views/auth/RegisterPage.vue";

// Embded
import EmbedTimeline from "@/views/embed/EmbedTimeline.vue";

// Public
import PublicTimeline from "@/views/PublicTimeline.vue";

//import Landing from "@/views/Landing.vue";
//import Index from "@/views/Index.vue";

import VueTailwindDatepicker from "vue-tailwind-datepicker";

// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/timelines",
        component: Timelines,
      },
      {
        path: "/admin/add/timeline",
        component: AddTimeline,
      },
      {
        path: "/admin/edit/timeline/:id",
        component: EditTimeline,
      },
    ],
  },
  {
    path: "/embed-timeline",
    name: "EmbedTimeline",
    component: EmbedTimeline,
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = store.getters["authModule/token"];
          if (isAuthenticated) {
            next("/admin/dashboard");
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: PublicTimeline,
  },
  { path: "/:pathMatch(.*)*", redirect: "/auth/login" },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/admin/timelines") {
    store.commit("notificationsModule/hideSuccessNotification");
    store.commit("notificationsModule/hideErrorNotification");
  }

  const isAuthenticated = store.getters["authModule/token"];

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/auth/login");
  } else {
    next();
  }
});

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueTailwindDatepicker);

store.dispatch("authModule/loadToken").then(() => {
  app.mount("#app");
});
