<template>
  <div class="pagination">
    <button @click="prevPage" :disabled="page <= 1">Previous</button>
    <span>Page {{ page }} of {{ totalPages }}</span>
    <button @click="nextPage" :disabled="page >= totalPages">Next</button>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    textColor: {
      type: String,
      default: "#fff",
    },
    linkColor: {
      type: String,
      default: "#72b3d0",
    },
  },
  methods: {
    nextPage() {
      this.$emit("updatePage", this.page + 1);
    },
    prevPage() {
      this.$emit("updatePage", this.page - 1);
    },
  },
  mounted() {
    document.documentElement.style.setProperty("--link-color", this.linkColor);
    document.documentElement.style.setProperty("--text-color", this.textColor);
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  font-size: 1rem;
  background-color: var(--link-color);
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: var(--link-color);
  opacity: 0.75;
}
</style>
