<template>
  <div class="relative min-h-screen">
    <navbar />
    <main>
      <section class="relative pt-44 pb-40 min-h-screen">
        <video
          autoplay
          muted
          loop
          class="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="@/assets/img/bkg-video-2.mp4" type="video/mp4" />
        </video>
        <div class="relative z-10 container mx-auto">
          <div class="max-w-4xl mx-auto">
            <h1 class="text-3xl font-semibold pb-6 text-center text-white">
              TIMELINE
            </h1>
            <div class="flex-container">
              <!-- Filters Section -->
              <div class="filters-section">
                <card-public-timeline />
              </div>
              <!-- Timeline Section -->
              <div class="timeline-section">
                <card-timeline />
              </div>
            </div>
          </div>
        </div>
        <div class="footer"><footer-component /></div>
      </section>
    </main>
  </div>
</template>

<script>
import CardPublicTimeline from "@/components/Cards/CardPublicTimelinePreview.vue";
import CardTimeline from "@/components/Cards/CardPublicTimeline.vue";
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";

export default {
  name: "add-view",
  components: {
    CardPublicTimeline,
    CardTimeline,
    Navbar,
    FooterComponent,
  },
};
</script>

<style>
.container {
  max-width: 100%;
}

.max-w-4xl {
  max-width: 80%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-5xl {
  font-size: 3rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.filters-section {
  width: 100%;
  padding: 1rem;
}

.timeline-section {
  width: 100%;
  padding: 1rem;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
}

.footer .text-sm {
  color: white;
  font-weight: 400;
}

@media (min-width: 1024px) {
  .filters-section {
    width: 23.333333%;
  }
  .timeline-section {
    width: 76.666667%;
  }
  .flex-container {
    flex-wrap: nowrap;
  }
}
</style>
