<template>
  <nav
    class="top-0 absolute z-50 w-full flex items-center justify-center px-2 py-3 navbar-expand-lg"
  >
    <div class="container px-4 mx-auto flex items-center justify-center">
      <div
        class="relative flex justify-center lg:w-auto lg:static lg:block lg:justify-center"
      >
        <a
          href="https://www.now.museum/"
          class="inline-block py-2"
          target="_blank"
          ><img
            src="@/assets/img/Logo-footer.png"
            alt="Now. Museum"
            class="h-16"
        /></a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
};
</script>

<style scoped>
nav {
  background-color: transparent;
}
</style>
