// src/utils/localForageConfig.js

import localforage from 'localforage';

localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL],
  name: 'nowmuseum',
  version: 1.0,
  size: 20 * 1024 * 1024, // 20MB
});
