<template>
  <div class="flex-auto px-4 lg:px-5 py-5 bkg-timeline">
    <div
      :class="{ loading: loading }"
      id="timelineContainer"
      class="timeline-embed"
      :style="{ color: textColor, width: width, height: height }"
    >
      <div class="loading-text theme-secondary" v-if="loading">Loading...</div>

      <div id="timelineContent" :style="{ color: textColor }"></div>

      <div
        v-if="!loading && (!timelines || timelines.length === 0)"
        class="text-center theme-secondary"
      >
        No results, refine your code in dashboard.
      </div>

      <div
        v-if="
          !loading &&
          timelines &&
          timelines.events &&
          timelines.events.length === 0
        "
        class="text-center theme-secondary"
      >
        No results, refine your code in dashboard.
      </div>
    </div>

    <pagination-component
      v-if="totalPages > 1"
      :page="page"
      :totalPages="totalPages"
      @updatePage="handlePageUpdate"
      :textColor="textColor"
      :linkColor="linkColor"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Timeline } from "@knight-lab/timelinejs";
import "@knight-lab/timelinejs/dist/css/timeline.css";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";

export default {
  name: "timeline-component",
  components: {
    PaginationComponent,
  },
  props: {
    searchQuery: String,
    country: String,
    language: String,
    date: String,
    textColor: {
      type: String,
      default: "#000000",
    },
    titleColor: {
      type: String,
      default: "#000000",
    },
    linkColor: {
      type: String,
      default: "#72b3d0",
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "550px"
    },
  },
  data() {
    return {
      timelineInstance: null,
      loading: false,
      page: 1,
      totalPages: 1,
    };
  },
  computed: {
    ...mapState("timelineDataModule", ["timelines"]),
  },
  methods: {
    ...mapActions("apiData", ["fetchApiKey"]),
    ...mapActions("timelineDataModule", ["fetchTimeline"]),

    async initializeTimeline() {
      this.loading = true;
      const timelineContent = document.getElementById("timelineContent");

      if (this.timelines && this.timelines.events) {
        // Clear the HTML content of timelineContent
        timelineContent.innerHTML = "";
        timelineContent.className = "";
      } else {
        // If no events, stop loading and exit
        timelineContent.className = "";
        this.loading = false;
        return;
      }

      if (this.timelineInstance) {
        this.timelineInstance = null;
      }

      await this.$nextTick();

      if (
        timelineContent &&
        this.timelines &&
        this.timelines.events &&
        this.timelines.events.length > 0
      ) {
        this.timelineInstance = new Timeline(timelineContent, {
          events: this.timelines.events,
        });
      }

      this.loading = false; // Stop loading
    },

    async loadTimelineData() {
      this.loading = true; // Start loading
      try {
        await this.fetchApiKey();
        await this.fetchTimeline({
          query: this.searchQuery,
          country: this.country,
          language: this.language,
          date: this.date,
          /*endDate: this.endDate,*/
          page: this.page, // Pass the current page to fetchTimeline
        });

        this.totalPages = this.timelines.totalpages; // Update the total pages
        await this.$nextTick();
        this.initializeTimeline();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false; // Stop loading
      }
    },

    handlePageUpdate(newPage) {
      this.page = newPage;
      this.loadTimelineData(); // Reload timeline data on page update
    },
  },
  mounted() {
    this.loadTimelineData();
  },
  beforeDestroy() {
    if (this.timelineInstance) {
      this.timelineInstance = null;
    }
  },
};
</script>

<style scoped>
.bkg-timeline {
    background-color: rgba(199,199,199,0.5);
    border-radius: 10px;
}

.timeline-embed {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timeline-embed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.timeline-embed.loading::before {
  display: flex;
}

.timeline-embed.loading .loading-text {
  position: absolute;
  color: white;
  font-size: 1.5rem;
  z-index: 9999;
}

.loading-text {
  display: none;
}

.timeline-embed.loading .loading-text {
  display: block;
}

.c-bkg-grey {
  background-color: rgba(226, 232, 240, 0.6);
}

.c-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
</style>
