<template>
  <main>
    <section
      class="relative flex flex-col min-w-0 break-words w-full rounded-lg c-bkg-grey border-0"
    >
      <card-timeline
        :searchQuery="searchQuery"
        :country="country"
        :language="language"
        :date="date"
        :textColor="textColor"
        :titleColor="titleColor"
        :linkColor="linkColor"
        :width="width"
        :height="height"
      />
    </section>
  </main>
</template>

<script>
import CardTimeline from "@/components/Cards/CardEmbedTimeline.vue";

export default {
  name: "add-view",
  components: {
    CardTimeline,
  },
  data() {
    return {
      searchQuery: this.$route.query.search || "",
      country: this.$route.query.country || "",
      language: this.$route.query.language || "",
      date: this.$route.query.date || "",
      textColor: this.$route.query.textColor || "#000000",
      titleColor: this.$route.query.titleColor,
      linkColor: this.$route.query.linkColor || "#72b3d0",
      width: this.$route.query.width || "100%",
      height: this.$route.query.height || "550px",
    };
  },
  mounted() {
    document.documentElement.style.setProperty(
      "--title-color",
      this.titleColor
    );
    document.documentElement.style.setProperty("--link-color", this.linkColor);
    document.documentElement.style.setProperty("--text-color", this.textColor);
  },
};
</script>

<style>
.timeline-embed #timelineContent.tl-timeline h1,
.timeline-embed #timelineContent.tl-timeline h2,
.timeline-embed #timelineContent.tl-timeline h3,
.timeline-embed #timelineContent.tl-timeline h4,
.timeline-embed #timelineContent.tl-timeline h5,
.timeline-embed #timelineContent.tl-timeline h6 {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
  color: var(--title-color) !important;
}

.timeline-embed
  #timelineContent.tl-timeline
  .tl-slide-content-container
  .tl-headline {
  font-size: 21px;
  line-height: 26px;
}

.timeline-embed #timelineContent.tl-timeline a {
  color: var(--link-color) !important;
}

.timeline-embed #timelineContent.tl-timeline .tl-text-content p {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
  color: var(--text-color) !important;
}

.timeline-embed #timelineContent.tl-timeline .tl-text-content p a {
  color: var(--link-color) !important;
}

.timeline-embed #timelineContent.tl-timeline .tl-headline-date,
.timeline-embed #timelineContent.tl-timeline .tl-headline-date small {
  color: var(--text-color) !important;
}

.container {
  max-width: 80%;
}

.max-w-4xl {
  max-width: 80%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-5xl {
  font-size: 3rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}
</style>
