export default {
  namespaced: true,
  state: {
    filters: {
      searchQuery: "",
      country: null,
      language: null,
      startDate: null,
      endDate: null,
    },
    embedCode: "Please select one filter",
    previewRequested: false,
    componentKey: 0,
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_EMBED_CODE(state, code) {
      state.embedCode = code;
    },
    SET_PREVIEW_REQUESTED(state, requested) {
      state.previewRequested = requested;
    },
    INCREMENT_COMPONENT_KEY(state) {
      state.componentKey += 1;
    },
  },
  actions: {
    updateFilters({ commit, dispatch }, filters) {
      commit("SET_FILTERS", filters);
      dispatch("generateEmbedCode");
    },
    previewTimeline({ commit }) {
      commit("SET_PREVIEW_REQUESTED", true);
      commit("INCREMENT_COMPONENT_KEY");
    },
    generateEmbedCode({ commit, state }) {
      const { searchQuery, country, language, startDate /*endDate*/} = state.filters;
      const baseUrl = process.env.VUE_APP_EMBED_URL;
      let params = [];

      if (searchQuery) params.push(`search=${encodeURIComponent(searchQuery)}`);
      if (country) params.push(`country=${encodeURIComponent(country)}`);
      if (language) params.push(`language=${encodeURIComponent(language)}`);
      if (startDate) params.push(`date=${encodeURIComponent(startDate)}`);
      /*if (endDate) params.push(`endDate=${encodeURIComponent(endDate)}`);*/

      const urlParams = params.length > 0 ? `?${params.join("&")}` : "";
      const code = `<iframe src="${baseUrl}${urlParams}" width="100%" height="645" frameborder="0"></iframe>`;

      commit("SET_EMBED_CODE", code);
    },
  },
  getters: {
    filters: (state) => state.filters,
    previewRequested: (state) => state.previewRequested,
    componentKey: (state) => state.componentKey,
  },
};
