<template>
  <div
    class="relative flex flex-col bg-white min-w-0 break-words w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 flex-1">
          <h3 class="font-semibold text-lg theme-secondary">Timelines</h3>
        </div>
        <div class="relative w-full px-4 flex-1 text-right">
          <router-link
            to="/admin/add/timeline"
            class="theme-primary-bk text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            Add
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="!isLoading" class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Name
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Search Query
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Country
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Language
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Start Date
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              End Date
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Date Created
            </th>
            <th
              class="px-6 custom-background text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(timeline, index) in timelines" :key="index">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              {{ timeline.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ timeline.searchQuery }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ getCountryNameByCode(timeline.country) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ timeline.language }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ timeline.startDate }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ timeline.endDate }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ timeline.dateCreated }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <table-dropdown :timelineId="timeline.id" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <loader v-else />
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/TableDropdown.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import Loader from "@/components/Loader/Loader.vue";

export default {
  components: {
    TableDropdown,
    Loader,
  },
  computed: {
    ...mapGetters("apiData", ["userId"]),
    ...mapGetters("apiData", ["timelines"]),
    ...mapGetters("loader", ["isLoading"]),
    ...mapState("timelineDataModule", ["countries"]),
    ...mapGetters("timelineDataModule", ["getCountryNameByCode"]),
  },
  methods: {
    ...mapActions("apiData", ["fetchUserData", "fetchUserTimelines"]),
    ...mapActions("timelineDataModule", ["fetchCountries"]),

    async refreshUser() {
      await this.fetchUserData();
    },
    async refreshTimelines() {
      await this.fetchTimelines();
    },
  },
  async mounted() {
    await this.refreshUser();
    await this.fetchUserTimelines();
    await this.fetchCountries();
  },
};
</script>
