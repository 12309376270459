<template>
  <div class="flex flex-wrap mt-4 h-screen-navbar">
    <div class="w-full mb-12 px-4">
      <!-- Notifications -->
      <inline-notification />

      <!-- Affiche la clé API -->
      <card-api-key />
      
      <!-- Affiche les timelines -->
      <card-timelines />
    </div>
  </div>
</template>
<script>
import CardTimelines from "@/components/Cards/CardTimelines.vue";
import InlineNotification from "@/components/Notifications/InlineNotification.vue";
import CardApiKey from "@/components/Cards/CardApiKey.vue";

export default {
  name: "timelines-view",
  components: {
    CardTimelines,
    InlineNotification,
    CardApiKey,
  },
};
</script>
