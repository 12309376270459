// store/modules/apiData.js

import apiService from "@/services/WpApiService";

export default {
  namespaced: true,
  state: {
    apiKey: null,
    timeline: [],
    timelines: [],
    userId: null,
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    aboutMe: "",
  },
  mutations: {
    SET_API_KEY(state, apiKey) {
      state.apiKey = apiKey;
    },
    SET_TIMELINE(state, timeline) {
      state.timeline = timeline;
    },
    SET_TIMELINES(state, timelines) {
      state.timelines = timelines;
    },
    SET_USER_DATA(state, userData) {
      state.userId = userData.id;
      state.username = userData.name;
      state.email = userData.email;
      state.firstName = userData.first_name;
      state.lastName = userData.last_name;
      state.aboutMe = userData.description;
    },
  },
  actions: {
    async fetchApiKey({ commit, dispatch, rootGetters }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        const data = await apiService.getApiKey(token);
        const apiKey = data.apiKey;

        commit("SET_API_KEY", apiKey);
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          "Please generate Api Key",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async generateApiKey({ commit, dispatch, rootGetters }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        const data = await apiService.generateApiKey(token);
        const apiKey = data.apiKey;


        dispatch(
          "notificationsModule/showSuccess",
          "Api Key generated successfully",
          {
            root: true,
          }
        );

        commit("SET_API_KEY", apiKey);
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving API key",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async fetchUserTimelines({ commit, dispatch, rootGetters }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        const userId = rootGetters["apiData/userId"];
        const timelines = await apiService.getUserTimelines(token, userId);

        commit("SET_TIMELINES", timelines);
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving timelines",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async fetchTimelineById({ commit, dispatch, rootGetters }, timelineId) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        const timeline = await apiService.getTimeline(token, timelineId);

        commit("SET_TIMELINE", timeline);
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving timelines",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async fetchUserData({ commit, dispatch, rootGetters }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");
        
        const userid = await apiService.getUserid(token);

        const userInfo = await apiService.getUserInfo(token, userid);
        commit("SET_USER_DATA", userInfo);
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving user data",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async addTimeline({ dispatch, rootGetters }, { timeline, navigate }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        if (!timeline.name) {
          dispatch("notificationsModule/showError", "Name required !", {
            root: true,
          });
        }

        await apiService.postTimelines(token, timeline);
        if (navigate) {
          navigate();
          dispatch(
            "notificationsModule/showSuccess",
            `Timeline "${timeline.name}" added successfully`,
            {
              root: true,
            }
          );
        }
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving timeline",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async editTimeline({ dispatch, rootGetters }, { timelineId, timeline }) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        if (!timeline.name) {
          dispatch("notificationsModule/showError", "Name required !", {
            root: true,
          });
        }

        await apiService.editTimelines(token, timelineId, timeline);

        dispatch(
          "notificationsModule/showSuccess",
          "Timeline edited successfully",
          {
            root: true,
          }
        );
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error retrieving timeline",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async deleteTimeline({ dispatch, rootGetters }, timelineId) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        await apiService.deleteUserTimeline(token, timelineId);
        await dispatch("fetchTimelines");

        dispatch(
          "notificationsModule/showSuccess",
          "Timeline deleted successfully",
          {
            root: true,
          }
        );
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error deleting timeline",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async updateUser({ commit, dispatch, rootGetters }, updatedData) {
      try {
        dispatch("loader/startLoading", null, { root: true });

        const token = rootGetters["authModule/token"];
        if (!token) throw new Error("No token found");

        const userId = rootGetters["apiData/userId"];
        const username = rootGetters["apiData/username"];

        await apiService.updateUserInfo(token, userId, updatedData);

        commit("SET_USER_DATA", {
          userId: userId,
          username: username,
          /*email: updatedData.email,*/
          firstName: updatedData.first_name,
          lastName: updatedData.last_name,
          aboutMe: updatedData.description,
        });

        dispatch(
          "notificationsModule/showSuccess",
          "User updated successfully",
          {
            root: true,
          }
        );
      } catch (error) {
        dispatch(
          "notificationsModule/showError",
          error.message || "Error updating user",
          {
            root: true,
          }
        );
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
  },
  getters: {
    apiKey: (state) => state.apiKey,
    timelines: (state) => state.timelines,
    timeline: (state) => state.timeline,
    userId: (state) => state.userId,
    username: (state) => state.username,
    email: (state) => state.email,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    aboutMe: (state) => state.aboutMe,
  },
};
