<template>
  <div
    v-if="showSuccessNotification || showErrorNotification"
    :class="['text-white px-6 py-4 border-0 rounded relative mb-4', bgColorClass]"
  >
    <span class="text-xl inline-block mr-5 align-middle">
      <i class="fas fa-bell"></i>
    </span>
    <span class="inline-block align-middle mr-8">
      <b class="capitalize">&nbsp;{{ title }}</b> {{ message }}
    </span>
    <button
      @click="hideNotification"
      class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
    >
      <span>×</span>
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    bgColor: String,
  },
  computed: {
    ...mapState("notificationsModule", {
      showSuccessNotification: (state) => state.showSuccessNotification,
      showErrorNotification: (state) => state.showErrorNotification,
      successMessage: (state) => state.successMessage,
      errorMessage: (state) => state.errorMessage,
    }),
    bgColorClass() {
      return this.showSuccessNotification ? "bg-emerald-500" : "bg-red-500";
    },
    title() {
      return this.showSuccessNotification ? "Success!" : "Error!";
    },
    message() {
      return this.showSuccessNotification ? this.successMessage : this.errorMessage;
    },
  },
  methods: {
    ...mapMutations("notificationsModule", [
      "hideSuccessNotification",
      "hideErrorNotification",
    ]),
    hideNotification() {
      if (this.showSuccessNotification) {
        this.hideSuccessNotification();
      } else if (this.showErrorNotification) {
        this.hideErrorNotification();
      }
    },
  },
};
</script>
