<template>
  <div class="w-full text-center h-screen-navbar">
    <div class="pt-32 m-pt sm:pt-0">
      <h2 class="font-semibold text-4xl theme-primary">
        Welcome to your dashboard
      </h2>
      <p class="mt-4 text-lg leading-relaxed theme-secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed
        dignissim mauris, id suscipit felis.
      </p>
      <div class="flex flex-wrap mt-8">
        <div class="w-full lg:w-6/12 px-4 m-btn">
          <router-link
            to="/admin/add/timeline"
            class="theme-primary-bk text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            Add Timeline
          </router-link>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <router-link
            to="/admin/timelines"
            class="theme-secondary text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            Managing timelines
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dashboard-page",
  components: {},
};
</script>

<style>
@media (max-width: 980px) {
  .m-btn {
    margin-bottom: 24px;
  }
  .m-pt {
    padding-top: 3rem;
  }
}
</style>
