<template>
  <div>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 c-shadow rounded-lg c-bkg-grey border-0"
    >
      <div class="flex-auto px-4 lg:px-5 py-5">
        <div
          :class="{ loading: loading }"
          id="timelineContainer"
          class="timeline-public"
        >
          <div class="loading-text theme-secondary" v-if="loading">Loading...</div>

          <div id="timelineContent"></div>

          <div
            v-if="!loading && (!timelines || timelines.length === 0)"
            class="text-center theme-secondary"
          >
            Please type your query and click preview.
          </div>

          <div
            v-if="
              !loading &&
              timelines &&
              timelines.events &&
              timelines.events.length === 0
            "
            class="text-center theme-secondary"
          >
            No results, refine your filters.
          </div>
        </div>

        <pagination-component
          v-if="totalPages > 1"
          :page="page"
          :totalPages="totalPages"
          @updatePage="handlePageUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { Timeline } from "@knight-lab/timelinejs";
import "@knight-lab/timelinejs/dist/css/timeline.css";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";

export default {
  name: "timeline-component",
  components: {
    PaginationComponent,
  },
  data() {
    return {
      timelineInstance: null,
      loading: false,
      page: 1,
      totalPages: 1,
    };
  },
  computed: {
    ...mapState("timelineDataModule", ["timelines"]),
    ...mapState("filterModule", ["filters", "previewRequested"]),
  },
  methods: {
    ...mapMutations("filterModule", ["SET_PREVIEW_REQUESTED"]),
    ...mapActions("apiData", ["fetchApiKey"]),
    ...mapActions("timelineDataModule", ["fetchTimeline"]),

    async initializeTimeline() {
      this.loading = true; // Indicate loading
      const timelineContent = document.getElementById("timelineContent");

      if (this.timelines && this.timelines.events) {
        // Clear the HTML content of timelineContent
        timelineContent.innerHTML = "";
        timelineContent.className = "";
      } else {
        // If no events, stop loading and exit
        timelineContent.className = "";
        this.loading = false;
        return;
      }
      
      if (this.timelineInstance) {
        this.timelineInstance = null;
      }

      await this.$nextTick();

      if (
        timelineContent &&
        this.timelines &&
        this.timelines.events &&
        this.timelines.events.length > 0
      ) {
        this.timelineInstance = new Timeline(timelineContent, {
          events: this.timelines.events,
        });
      }

      this.SET_PREVIEW_REQUESTED(false);
      this.loading = false; // Stop loading
    },

    async handlePreviewRequested() {
      if (this.previewRequested) {
        this.loading = true; // Start loading
        try {
          await this.fetchApiKey();
          await this.fetchTimeline({
            query: this.filters.searchQuery,
            country: this.filters.country,
            language: this.filters.language,
            date: this.filters.startDate,
            page: this.page, // Pass the current page to fetchTimeline
          });

          this.totalPages = this.timelines.totalpages; // Update the total pages
          await this.$nextTick();
          this.initializeTimeline();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this.loading = false; // Stop loading
          this.SET_PREVIEW_REQUESTED(false);
        }
      }
    },

    handlePageUpdate(newPage) {
      this.page = newPage;
      this.SET_PREVIEW_REQUESTED(true); // Trigger timeline regeneration
    },
  },
  watch: {
    previewRequested() {
      this.handlePreviewRequested();
    },
  },
  beforeDestroy() {
    if (this.timelineInstance) {
      this.timelineInstance = null;
    }
  },
};
</script>

<style scoped>
.timeline-public {
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timeline-public::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.timeline-public.loading::before {
  display: flex;
}

.timeline-public.loading .loading-text {
  position: absolute;
  color: white;
  font-size: 1.5rem;
  z-index: 9999;
}

.loading-text {
  display: none;
}

.timeline-public.loading .loading-text {
  display: block;
}

.c-bkg-grey {
  background-color: rgba(226, 232, 240, 0.6);
}

.c-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
</style>
