<template>
  <footer
    class="pb-6"
    :class="[
      absolute
        ? 'absolute w-full bottom-0 bg-blueGray-800 bg-opacity-60'
        : 'relative',
    ]"
  >
    <div class="container mx-auto px-4">
      <hr class="mb-6 border-b-1 border-blueGray-600" />
      <div class="flex flex-wrap items-center justify-center">
        <div class="w-full md:w-4/12 px-4">
          <div class="text-sm text-white font-semibold py-1 text-center">
            Copyright © {{ date }} Now. Museum by
            <a
              href="https://atcreative.fr/"
              target="_blank"
              class="theme-primary hover:text-blueGray-300 text-sm font-semibold py-1"
            >
              AT Creative
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
