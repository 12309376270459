<template>
  <div class="parent-loader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "loader-component",
};
</script>

<style>
.parent-loader {
    text-align: center;
}

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(85, 85, 85, 0.3); 
  border-radius: 50%;
  border-top-color: #555555; 
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
</style>