<template>
  <div>
    <!-- Timeline -->
    <div class="flex flex-wrap">
      <!-- Search Input -->
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            for="search"
          >
            Search *
            <span v-if="searchError" class="alert-error">
              Please enter a search term with at least {{max_query}} characters.
            </span>
          </label>
          <input
            v-model="localSearchQuery"
            type="text"
            placeholder="Search..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            required
          />
        </div>
      </div>

      <!-- Country Select -->
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            for="country"
          >
            Country
          </label>
          <model-list-select
            v-model="localSelectedCountry"
            :list="countries"
            option-value="code"
            option-text="name"
            placeholder="Select a country..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>

      <!-- Date Filter -->
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            for="date"
          >
            Date
          </label>
          <vue-tailwind-datepicker
            v-model="dateValue"
            :formatter="formatter"
            :disable-date="disableFutureDates"
            :disableInRange="disableFutureDates"
            as-single
          />
        </div>
      </div>

      <!-- Language Select -->
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            for="language"
          >
            Language
          </label>
          <model-list-select
            v-model="localSelectedLanguage"
            :list="languages"
            option-value="code"
            option-text="name"
            placeholder="Select a language..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    VueTailwindDatepicker,
    ModelListSelect,
  },
  props: {
    timeline: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      localSelectedCountry: "",
      localSelectedLanguage: "",
      dateValue: [new Date().toISOString().split("T")[0]],
      formatter: {
        date: "YYYY-MM-DD",
        month: "MM",
      },
      localSearchQuery: "",
      searchError: false,
      max_query : process.env.VUE_APP_MAX_SEARCH_QUERY || 5,
    };
  },
  computed: {
    ...mapState("timelineDataModule", ["countries", "languages"]),
    ...mapGetters("filterModule", ["filters"]),
  },
  methods: {
    ...mapActions("timelineDataModule", [
      "fetchCountries",
      "fetchLanguages",
      "fetchTimeline",
    ]),
    ...mapActions("filterModule", ["updateFilters"]),
    onApplyFilters() {
      if (this.localSearchQuery.length < this.max_query) {
        this.searchError = true;
        return;
      }
      this.searchError = false;
      const filtersNew = {
        searchQuery: this.localSearchQuery,
        country: this.localSelectedCountry,
        language: this.localSelectedLanguage,
        startDate: this.dateValue[0],
        endDate: this.dateValue[1],
      };
      this.updateFilters(filtersNew);
    },
    disableFutureDates(date) {
      return date > new Date();
    },
  },
  async mounted() {
    await this.fetchCountries();
    await this.fetchLanguages();
  },
  watch: {
    localSearchQuery: "onApplyFilters",
    localSelectedCountry: "onApplyFilters",
    localSelectedLanguage: "onApplyFilters",
    dateValue: "onApplyFilters",
    timeline: {
      deep: true,
      handler(newVal) {
        if (
          this.dateValue[0] !== newVal.startDate &&
          this.dateValue[1] !== newVal.endDate
        ) {
          const newDateValue = [];
          if (newVal.startDate) {
            newDateValue.push(newVal.startDate);
          }
          if (newVal.endDate) {
            newDateValue.push(newVal.endDate);
          }
          this.dateValue = newDateValue;
        }
        if (this.localSelectedCountry !== newVal.country) {
          this.localSelectedCountry = newVal.country;
        }
        if (this.localSelectedLanguage !== newVal.language) {
          this.localSelectedLanguage = newVal.language;
        }
        if (this.localSearchQuery !== newVal.searchQuery) {
          this.localSearchQuery = newVal.searchQuery;
        }
      },
    },
  },
};
</script>

<style>
.text-vtd-secondary-400 {
  color: rgb(156 163 175 / 0.5);
}
.text-vtd-secondary-700 {
  font-weight: 600;
}
.bg-vtd-primary-500 {
  background-color: #72b3d0;
}
.alert-error {
  color: red;
  text-transform: math-auto;
  font-weight: 400;
}
</style>
