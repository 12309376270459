// store/modules/rememberMeModule.js

export default {
    namespaced: true,
  
    state: {
      rememberMe: false,
    },
  
    mutations: {
      SET_REMEMBER_ME(state, value) {
        state.rememberMe = value;
      },
    },
  
    actions: {
      saveRememberMe({ commit }, value) {
        commit('SET_REMEMBER_ME', value);
      },
    },
  
    getters: {
      rememberMe: state => state.rememberMe,
    },
  };
  