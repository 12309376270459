<template>
  <div>
    <!-- Notifications -->
    <inline-notification />

    <!-- Loader -->
    <loader v-if="isLoading" />

    <div
      v-if="!isLoading"
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-2xl rounded-lg bg-blueGray-100 border-0"
    >
      <!-- ... -->
      <div class="flex-auto px-4 lg:px-10 py-10 pt-5">
        <form @submit.prevent="updateUserForm">
          <!-- Profil -->
          <h6 class="theme-primary text-sm mt-3 mb-6 font-bold uppercase">
            Profil
          </h6>
          <!-- ... -->
          <div class="flex flex-wrap">
            <!-- Username -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="form_username"
                >
                  Username
                </label>
                <input
                  id="form_username"
                  name="form_username"
                  v-model="form_username"
                  type="text"
                  :class="{
                    'bg-gray-200 border-gray-200 text-gray-500': isDisabled,
                  }"
                  :disabled="isDisabled"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <!-- Email -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="form_email,"
                >
                  Email address
                </label>
                <input
                  id="form_email,"
                  name="form_email,"
                  v-model="form_email"
                  :disabled="isDisabled"
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <!-- First Name -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="form_firstName"
                >
                  First Name
                </label>
                <input
                  id="form_firstName"
                  name="form_firstName"
                  v-model="form_firstName"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <!-- Last Name -->
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="form_lastName"
                >
                  Last Name
                </label>
                <input
                  id="form_lastName"
                  name="form_lastName"
                  v-model="form_lastName"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </div>
          <!-- Password -->
          <!--<h6 class="theme-primary text-sm mt-3 mb-6 font-bold uppercase">
            Password
          </h6>-->
          <!-- ... -->
          <!-- <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="newPassword"
                >
                  New Password
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  v-model="newPassword"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm New Password
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  v-model="confirmPassword"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                />
              </div>
            </div>
          </div>-->
          <!-- About Me -->
          <hr class="mt-6 border-b-1 border-blueGray-300" />
          <h6 class="theme-primary text-sm mt-3 mb-6 font-bold uppercase">
            About Me
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="form_aboutMe"
                >
                  About me
                </label>
                <textarea
                  id="form_aboutMe"
                  name="form_aboutMe"
                  v-model="form_aboutMe"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Bouton de mise à jour -->
          <div class="text-center mt-6">
            <button
              class="theme-primary-bk text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Mettre à jour
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InlineNotification from "@/components/Notifications/InlineNotification.vue";
import Loader from "@/components/Loader/Loader.vue";

export default {
  components: {
    Loader,
    InlineNotification,
  },
  data() {
    return {
      isDisabled: true,
      /*newPassword: "",
      confirmPassword: "",*/
      form_username: "", // Ajoutez ces variables pour pré-remplir le formulaire
      form_email: "",
      form_firstName: "",
      form_lastName: "",
      form_aboutMe: "",
    };
  },
  computed: {
    ...mapGetters("apiData", [
      "userId",
      "username",
      "email",
      "firstName",
      "lastName",
      "aboutMe",
    ]),
    ...mapGetters("notifications", [
      "showSuccessNotification",
      "showErrorNotification",
    ]),
    ...mapGetters("loader", ["isLoading"]),
  },
  methods: {
    ...mapActions("apiData", ["fetchUserData", "updateUser"]),
    ...mapActions("notifications", ["showSuccess", "showError"]),
    ...mapActions("loader", ["startLoading", "stopLoading"]),

    async updateUserForm() {
      const updatedData = {
        /*email: this.form_email,*/
        first_name: this.form_firstName,
        last_name: this.form_lastName,
        description: this.form_aboutMe,
      };

      this.updateUser(updatedData);
    },

    async assignFormData() {
      await this.fetchUserData();
      this.form_username = this.username;
      this.form_email = this.email;
      this.form_firstName = this.firstName;
      this.form_lastName = this.lastName;
      this.form_aboutMe = this.aboutMe;
    },
  },
  mounted() {
    this.assignFormData();
  },
};
</script>

<style scoped>
input:disabled, textarea:disabled {
  background-color: #E5E7EB;
  border-color: #E5E7EB; 
  color: #6B7280;           
  cursor: not-allowed;
}
</style>
