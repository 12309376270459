import axios from "axios";

export const WpApiService = {
  async signIn(email, password) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/jwt-auth/v1/token`,
      {
        username: email,
        password: password,
      }
    );
    return response.data;
  },
  async getUserid(token) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/wp/v2/users/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.id;
  },
  async getUserInfo(token, userId) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/wp/v2/users/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async updateUserInfo(token, userId, userData) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/wp/v2/users/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },
  async getUserApiKey(token) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/apikey`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async getUserTimelines(token, userId) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/timelines/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async getTimeline(token, timelineId) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/timeline/${timelineId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async postTimelines(token, timelinesData) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/timeline`,
      timelinesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },
  async editTimelines(token, timelineId, timelinesData) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/timeline/${timelineId}`,
      timelinesData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },
  async deleteUserTimeline(token, timelineId) {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/timeline/${timelineId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async getApiKey(token) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/apikey`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },
  async generateApiKey(token) {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/nowmuseum/v1/apikey/generate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  },
};

export default WpApiService;
