// store/modules/notificationsModule.js

export default {
  namespaced: true,
  state: {
    showSuccessNotification: false,
    showErrorNotification: false,
    successMessage: "",
    errorMessage: "",
  },
  mutations: {
    showSuccessNotification(state, successMessage) {
      state.showSuccessNotification = true;
      state.successMessage = successMessage;
    },
    hideSuccessNotification(state) {
      state.showSuccessNotification = false;
      state.successMessage = "";
    },
    showErrorNotification(state, errorMessage) {
      state.showErrorNotification = true;
      state.errorMessage = errorMessage;
    },
    hideErrorNotification(state) {
      state.showErrorNotification = false;
      state.errorMessage = "";
    },
  },
  actions: {
    showSuccess({ commit }, message) {
      commit("showSuccessNotification", message);
      setTimeout(() => {
        commit("hideSuccessNotification");
      }, 3000); // Masquer la notification après 3 secondes
    },
    showError({ commit }, message) {
      commit("showErrorNotification", message);
      setTimeout(() => {
        commit("hideErrorNotification");
      }, 3000); // Masquer la notification après 3 secondes
    },
  },
  getters: {
    showSuccessNotification: (state) => state.showSuccessNotification,
    showErrorNotification : (state) => state.showErrorNotification,
    successMessage: (state) => state.successMessage,
    errorMessage: (state) => state.errorMessage,
  },
};