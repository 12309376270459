<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <card-edit-timeline />
      <card-timeline />
    </div>
  </div>
</template>
<script>
import CardEditTimeline from "@/components/Cards/CardEditTimeline.vue";
import CardTimeline from "@/components/Cards/CardTimeline.vue";

export default {
  name: "edit-view",
  components: {
    CardEditTimeline,
    CardTimeline,
  },
};
</script>
