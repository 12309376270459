<template>
  <footer class="w-full bottom-0 right-0 left-0">
    <div class="w-full">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div
        class="w-full text-sm text-blueGray-500 font-semibold text-center"
      >
        Copyright © {{ date }} Now. Museum by
        <a
          href="https://atcreative.fr/"
          target="_blank"
          class="text-blueGray-500 hover:text-blueGray-700 text-sm font-semibold py-1"
        >
          AT Creative
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
