// store/modules/timelineDataModule.js

import TimelineService from "@/services/TimelineApiService";

export default {
  namespaced: true,
  state: {
    timelines: [],
    countries: [],
    languages: [],
  },
  mutations: {
    SET_TIMELINES(state, timelines) {
      state.timelines = timelines;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_LANGUAGES(state, languages) {
      state.languages = languages;
    },
  },
  actions: {
    async fetchTimeline(
      { commit, dispatch, rootGetters },
      { query, country, language, date, page }
    ) {
      const token = rootGetters["apiData/apiKey"] || process.env.VUE_APP_API_KEY_PUBLIC;

      if (!query || !token) {
        dispatch(
          "notificationsModule/showError",
          "Query and token fields required",
          {
            root: true,
          }
        );
        return;
      }

      try {
        const timelines = await TimelineService.fetchTimelines(
          query,
          token,
          country,
          language,
          date,
          page
        );

        commit("SET_TIMELINES", timelines);
      } catch (error) {
        dispatch("notificationsModule/showError", "Error fetching timelines", {
          root: true,
        });
        throw error;
      } finally {
        dispatch("loader/stopLoading", null, { root: true });
      }
    },
    async fetchCountries({ commit, dispatch }) {
      try {
        const countries = await TimelineService.fetchCountries();
        commit("SET_COUNTRIES", countries);
      } catch (error) {
        dispatch("notificationsModule/showError", "Error fetching countries", {
          root: true,
        });
        throw error;
      }
    },
    async fetchLanguages({ commit, dispatch }) {
      try {
        const languages = await TimelineService.fetchLanguages();
        commit("SET_LANGUAGES", languages);
      } catch (error) {
        dispatch("notificationsModule/showError", "Error fetching languages", {
          root: true,
        });
        throw error;
      }
    },
  },
  getters: {
    timelines: (state) => state.timelines,
    countries: (state) => state.countries,
    languages: (state) => state.languages,
    getCountryNameByCode: (state) => (countryCode) => {
      const country = state.countries.find((c) => c.code === countryCode);
      return country ? country.name : null;
    },
  },
};
