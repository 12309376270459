<template>
  <div>
    <div
      class="relative c-bkg-grey flex flex-col min-w-0 break-words w-full mb-6 c-shadow rounded-lg border-0"
    >
      <div class="flex-auto px-4 lg:px-5 py-5">
        <form @submit.prevent="createTimeline">
          <!-- Timeline -->
          <p class="theme-secondary text-sm mb-6 text-center">
            Use our filters to obtain the desired result and then click on
            previeuw to see the timeline.
          </p>

          <timeline-filter-components />

          <!-- Boutons de création et de prévisualisation -->
          <div class="text-center mt-6">
            <button
              @click="previewTimeline"
              :disabled="isSearchQueryValid"
              class="theme-primary-bk copy-btn text-white active:theme-secondary-bk text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              :title="
                filters.searchQuery.length < max_query
                  ? `Search term must be at least ${max_query} characters long`
                  : ''
              "
            >
              Preview
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import TimelineFilterComponents from "@/components/Filters/TimelinePublicFilterComponents.vue";

export default {
  components: {
    TimelineFilterComponents,
  },
  data() {
    return {
      timeline: [],
      name: "",
      max_query : process.env.VUE_APP_MAX_SEARCH_QUERY || 5,
      isSearchQueryValid: false,
    };
  },
  computed: {
    ...mapState("filterModule", ["filters", "previewRequested"]),
  },
  watch: {
    "filters.searchQuery"(newQuery) {
      this.isSearchQueryValid = newQuery.length <= this.max_query;
    },
  },
  methods: {
    ...mapActions("filterModule", ["updateFilters", "previewTimeline"]),
    ...mapMutations("filterModule", ["SET_PREVIEW_REQUESTED", "INCREMENT_COMPONENT_KEY"]),

    async createTimeline() {
      this.timeline = {
        name: this.name,
        searchQuery: this.filters.searchQuery,
        country: this.filters.country,
        language: this.filters.language,
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };
    },

    async previewTimeline() {
      try {
        this.SET_PREVIEW_REQUESTED(true);
        this.INCREMENT_COMPONENT_KEY();
      } catch (error) {
        this.showError("Error generating preview");
      }
    },

    async simulatePreview() {
      if (!this.isSearchQueryValid) {
        await this.previewTimeline();
      }
    },
  },
  mounted() {
    this.isSearchQueryValid = this.filters.searchQuery.length <= this.max_query;
    this.filters.searchQuery = process.env.VUE_APP_SEARCH_QUERY || "Covid";
    this.filters.country = process.env.VUE_APP_CONTRY || "France";
    this.filters.startDate = new Date().toISOString().split("T")[0] ;
    if (this.timeline) {
      this.name = this.timeline.name;
      this.$nextTick(this.simulatePreview);
    }
  },
};
</script>

<style>
.copy-btn {
  cursor: pointer;
}

.timeline-public .tl-timeline h1,
.timeline-public .tl-timeline h2,
.timeline-public .tl-timeline h3,
.timeline-public .tl-timeline h4,
.timeline-public .tl-timeline h5,
.timeline-public .tl-timeline h6 {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
}

.timeline-public .tl-timeline .tl-slide-content-container .tl-headline {
  font-size: 21px;
  line-height: 26px;
}

.timeline-public .tl-timeline a {
  color: #72b3d0 !important;
}

.timeline-public .tl-timeline .tl-text-content p {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif !important;
  color: #000 !important;
  font-size: 14px !important;
}

.timeline-public .tl-timeline .tl-text-content p a {
  color: #72b3d0 !important;
}

.timeline-public .tl-timeline .tl-headline-date,
.timeline-public .tl-timeline .tl-headline-date small {
  color: #000 !important;
}

h2.theme-primary {
  font-size: 18px;
  text-align: center;
}

.c-bkg-grey {
  background-color: rgba(226, 232, 240, 0.6);
}

.c-title {
  color: #e1dad3;
  font-size: 18px;
}

.c-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
</style>
