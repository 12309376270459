import axios from "axios";

const apiUrl = process.env.VUE_APP_TIMELINE_API;

const TimelineService = {
  async fetchTimelines(query, token, country, language, date, page) {
    const limit = 30;
    const params = {
      query,
      token,
      country,
      language,
      date,
      page,
      limit
    };

    const response = await axios.get(`${apiUrl}/events`, {
      params: params,
    });
    return response.data;
  },
  async fetchCountries() {
    const response = await axios.get(`${apiUrl}/countries`);
    const data = response.data;
    return Object.entries(data).map(([key, name]) => ({
      code: key.split(":")[1],
      name,
    }));
  },
  async fetchLanguages() {
    const response = await axios.get(`${apiUrl}/countries`);
    const data = response.data;
    return Object.entries(data).map(([key, name]) => ({
      code: key.split(":")[1],
      name,
    }));
  },
};

export default TimelineService;
