<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardSettings />
    </div>
  </div>
</template>
<script>
import CardSettings from "@/components/Cards/CardSettings.vue";

export default {
  name: "settings-view",
  components: {
    CardSettings,
  },
};
</script>
