<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <card-add-timeline />
      <card-timeline />
    </div>
  </div>
</template>
<script>
import CardAddTimeline from "@/components/Cards/CardAddTimeline.vue";
import CardTimeline from "@/components/Cards/CardTimeline.vue";

export default {
  name: "add-view",
  components: {
    CardAddTimeline,
    CardTimeline,
  },
};
</script>
